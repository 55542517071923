import htmx from 'htmx.org';

export function initializeModalButtons() {
    const BtnModalId = document.querySelectorAll('.btn-modal-id');

    BtnModalId.forEach(BtnModal => {
        BtnModal.addEventListener('click', async () => {
            const id = BtnModal.getAttribute('data-id');
            const type = BtnModal.getAttribute('data-modal-type');

            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                mode: 'no-cors'
            };

            const form = new FormData();
            form.append('action', 'modal_type');
            form.append('id', id);
            form.append('type', type);
            const params = new URLSearchParams(form);
            options.body = params;

            try {
                let response = await fetch('/wp-admin/admin-ajax.php', options);
                let html = await response.text();
                if (html) {
                    const modalContent = document.querySelector('.modal-service');
                    modalContent.innerHTML = html;

                    const form = modalContent.querySelector('.form');
                    if (form) {
                        htmx.process(form);
                    }

                    const event = new CustomEvent('ajaxContentLoaded', {
                        detail: { modalContent },
                    });
                    document.dispatchEvent(event);
                }
            } catch (error) {
                console.error('Error loading modal content:', error);
            }
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initializeModalButtons();
});
