document.addEventListener('DOMContentLoaded', function () {
    const header = document.querySelector('.header');
    const firstSection = document.querySelector('section');
    const content = document.querySelector('.margin');
    const modals = document.querySelectorAll('.modal__overlay');
    const containers = document.querySelectorAll('.container');
    const main = document.querySelector('main');
    let contentPaddingTop = 0;

    if (header) {
        function updateContentPadding() {
            const headerHeight = header.offsetHeight;
            const contentPaddingTop = headerHeight + 40;

            if (firstSection.classList.contains('not-found-section')) {
                firstSection.style.paddingTop = `${headerHeight + 10}px`;
            } else if (firstSection && !firstSection.classList.contains('banner') && !firstSection.classList.contains('not-found-section')) {
                main.style.paddingTop = `${contentPaddingTop}px`;
            }

            if (content && (header.classList.contains('header-first') || header.classList.contains('header-second') || header.classList.contains('header-sixth'))) {
                if (firstSection.classList.contains('banner')) {
                    content.style.paddingTop = `${contentPaddingTop}px`;
                }
            } else {
                if (firstSection.classList.contains('banner-second') || firstSection.classList.contains('banner-first')) {
                    const bannerHeight = window.innerHeight;
                    firstSection.style.height = `${bannerHeight - headerHeight}px`;
                    main.style.paddingTop = `${headerHeight}px`;
                } else if (firstSection.classList.contains('banner') && !firstSection.classList.contains('banner-second') && !firstSection.classList.contains('banner-first')) {
                    main.style.paddingTop = `${contentPaddingTop}px`;
                }
            }
        }

        updateContentPadding();
        window.addEventListener('resize', updateContentPadding);
    }

    const headerSixth = document.querySelector('.header-sixth');
    const MaxWidth = document.querySelector('.max-width');
    if (headerSixth) {
        containers.forEach(container => {
            container.style.paddingLeft = '85px';
            if (MaxWidth) {
                MaxWidth.style.paddingLeft = '65px';
            }
            if (window.innerWidth <= 480) {
                container.style.paddingLeft = '15px';
                if (MaxWidth) {
                    MaxWidth.style.paddingLeft = '0';
                }
            }
        });
    }

    const parallaxElement = document.querySelector('.parallax');

    if (parallaxElement) {
        const parentContainer = document.querySelector('.parallax-container');

        if (parentContainer) {
            function applyParallaxEffect() {
                if (window.innerWidth <= 1120) {
                    parallaxElement.style.transform = 'translateY(0)';
                    return;
                }

                const containerRect = parentContainer.getBoundingClientRect();
                const parallaxHeight = parallaxElement.offsetHeight;
                const containerHeight = parentContainer.offsetHeight;

                const parentPaddingTop = parseFloat(getComputedStyle(parentContainer).paddingTop) || 0;

                const maxScrollAmount = Math.max(0, containerHeight - parallaxHeight - contentPaddingTop - parentPaddingTop);

                if (containerRect.top <= window.innerHeight && containerRect.bottom >= 0) {
                    let scrollAmount = window.scrollY - parentContainer.offsetTop;

                    scrollAmount = Math.max(0, Math.min(scrollAmount, maxScrollAmount));

                    parallaxElement.style.transform = `translateY(${scrollAmount}px)`;
                }
            }

            applyParallaxEffect();

            window.addEventListener('scroll', applyParallaxEffect);
            window.addEventListener('resize', applyParallaxEffect);
        }
    }
});