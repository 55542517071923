document.addEventListener('DOMContentLoaded', () => {
    const assignDataIndex = () => {
        const showMoreButtons = document.querySelectorAll('.gallery-show-more');
        showMoreButtons.forEach((button, index) => {
            button.setAttribute('data-index', index + 1);
        });
    };

    assignDataIndex();
});


document.addEventListener('DOMContentLoaded', () => {
    const addShowMoreButtonListeners = () => {
        const showMoreButtons = document.querySelectorAll('.gallery-show-more');

        showMoreButtons.forEach((button) => {

            button.addEventListener('click', async (event) => {
                const button = event.target;
                const section = button.closest('section');
                const sectionId = section?.id;

                if (!section || !sectionId) {
                    console.error('Не удалось найти родительский блок секции.');
                    return;
                }

                const postId = button.getAttribute('data-post-id');
                const number = parseInt(button.getAttribute('data-number'));
                const offset = parseInt(button.getAttribute('data-offset'));
                const type = button.getAttribute('data-type');
                const gap = button.getAttribute('data-gap');
                const index = button.getAttribute('data-index'); 

                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    mode: 'no-cors',
                };

                const form = new FormData();
                form.append('action', 'load_more_gallery');
                form.append('post_id', postId);
                form.append('number', number);
                form.append('offset', offset);
                form.append('type', type);
                form.append('gap', gap);
                form.append('index', index); 

                const params = new URLSearchParams(form);
                options.body = params;

                try {
                    let response = await fetch('/wp-admin/admin-ajax.php', options);
                    let html = await response.text();
                    if (html) {
                        const galleryList = section.querySelector('.gallery-content-block');
                        if (galleryList) {
                            galleryList.innerHTML = html;

                            // Пересоздаём обработчики для новых кнопок
                            addShowMoreButtonListeners();
                        }
                    }
                } catch (error) {
                    console.error('Ошибка при загрузке галереи:', error);
                }
            });
        });
    };

    addShowMoreButtonListeners();
});






