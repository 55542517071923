document.addEventListener('click', function (event) {
    const form = document.querySelector('.form-card');

    if (event.target.closest('.field')) {
        let field = event.target.closest('.field');
        let input = field.querySelector('.field__input');

        if (input) {
            input.focus();
        }
    }
    else if (form && !form.contains(event.target)) {
        let isEmpty = Array.from(form.querySelectorAll('.field__input')).every(input => input.value === '' || input.value === '+7 (___) ___-__-__');

        if (isEmpty) {
            form.classList.remove('open');
        }
    }
});

document.addEventListener('focusin', function (event) {
    if (event.target.classList.contains('field__input')) {
        event.target.closest('.field').classList.add('active');
    }
});

document.addEventListener('focusout', function (event) {
    if (event.target.classList.contains('field__input')) {
        checkField(event.target);
    }
});

function checkField(element) {
    if (element.value === '' || element.value === '+7 (___) ___-__-__') {
        element.closest('.field').classList.remove('active');
    } else {
        element.closest('.field').classList.add('active');
    }
}


document.addEventListener('DOMContentLoaded', function () {
    const fileUploadField = document.querySelector('.file-upload');
    if (fileUploadField) {
        const fileInput = fileUploadField.querySelector('.file-upload__input');
        const fileUploadText = fileUploadField.querySelector('.file-upload__text');

        fileUploadField.addEventListener('dragover', (event) => {
            event.preventDefault();
            fileUploadField.classList.add('file-upload--dragover');
        });

        fileUploadField.addEventListener('dragleave', () => {
            fileUploadField.classList.remove('file-upload--dragover');
        });

        fileUploadField.addEventListener('drop', (event) => {
            event.preventDefault();
            fileUploadField.classList.remove('file-upload--dragover');

            if (event.dataTransfer.files.length) {
                fileInput.files = event.dataTransfer.files;
                fileUploadText.textContent = event.dataTransfer.files[0].name;
            }
        });
        fileInput.addEventListener('change', () => {
            if (fileInput.files.length) {
                fileUploadText.textContent = fileInput.files[0].name;
            } else {
                fileUploadText.textContent = 'Выберите файл или перетащите сюда';
            }
        });
    }
});
