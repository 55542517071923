document.addEventListener('DOMContentLoaded', () => {
    const coordButtons = document.querySelectorAll('.coords');

    coordButtons.forEach(button => {
        button.addEventListener('click', () => {
            const targetCount = button.getAttribute('data-count'); 

            document.querySelectorAll('.coords-element').forEach(element => {
                element.classList.remove('active');
            });

            const targetElement = document.querySelector(`.coords-element[data-count="${targetCount}"]`);
            if (targetElement) {
                targetElement.classList.add('active');
            }
        });
    });
});

