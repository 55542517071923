function checkSafariVersion() {
    const userAgent = navigator.userAgent;
    const safariMatch = userAgent.match(/Version\/(\d+)/);
    if (safariMatch) {
        const safariVersion = parseInt(safariMatch[1], 10);

        if (safariVersion < 16) {
            document.querySelector('main').style.display = 'none';
            document.querySelector('footer').style.display = 'none';
            document.getElementById('version-check').style.display = 'flex';
        }
    }
}

checkSafariVersion()