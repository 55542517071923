
const menuToggle = document.querySelector('#menu-toggle');
const mobileMenu = document.querySelector('#mobile-menu');
const header = document.querySelector('.header');
const fon = document.querySelector('.fon');
const body = document.body;
const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

function resetMenuState() {
    if (menuToggle && menuToggle.classList.contains('active')) {
        menuToggle.classList.remove('active');
    }
    if (mobileMenu && mobileMenu.classList.contains('active')) {
        mobileMenu.classList.remove('active');
    }
    if (header && header.classList.contains('active')) {
        header.classList.remove('active');
    }
    if (fon && fon.classList.contains('active')) {
        fon.classList.remove('active');
    }
    body.style.overflow = '';
}

if (menuToggle) {
    menuToggle.addEventListener('click', function () {
        this.classList.toggle('active');

        if (mobileMenu) {
            mobileMenu.classList.toggle('active');

            if ((header.classList.contains('header-first') || header.classList.contains('header-second')) && scrollbarWidth === 0) {
                body.style.overflow = mobileMenu.classList.contains('active') ? 'hidden' : '';
                body.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
                header.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
                mobileMenu.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
            }

            if (!(header.classList.contains('header-first') || header.classList.contains('header-second'))) {
                body.style.overflow = mobileMenu.classList.contains('active') ? 'hidden' : '';
                body.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
                header.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
                mobileMenu.style.paddingRight = mobileMenu.classList.contains('active') ? `${scrollbarWidth}px` : '';
            }
        }

        if (header) {
            header.classList.toggle('active');
        }

        if (fon) {
            fon.classList.toggle('active');
        }
    });

}



const anchorLinks = document.querySelectorAll('a[href^="#"]');
anchorLinks.forEach(link => {
    link.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            const headerHeight = header ? header.offsetHeight : 0;
            const additionalOffset = window.innerWidth >= 1024 ? 150 : 55;
            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = targetPosition - headerHeight - additionalOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }

        resetMenuState();
    });
});


