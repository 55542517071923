import Map from "../libs/Map";

const { zoom, markerUrl } = window.mapConfig;

const mapZoom = zoom;
const customMarker = markerUrl;

export default (coordinates = "", zoom = mapZoom) => ({
    async init() {
        await Map.loadAPI();

        const coordsArray = coordinates
            .split(";")
            .map(coordStr => coordStr.split(",").map(value => parseFloat(value.trim())))
            .filter(coord => coord.length === 2 && coord.every(Number.isFinite));
        if (!coordsArray.length) {
            console.warn("Координаты не найдены или неверного формата.");
            return;
        }

        const map = new Map({
            container: this.$root,
            center: [coordsArray[0][1], coordsArray[0][0]],
            zoom,
        });
        coordsArray.forEach(([lat, lng]) => {
            map.addMarker([lng, lat], Map.createDefaultMarker(customMarker));
        });

        const moveButtons = document.querySelectorAll('.move-it');

        moveButtons.forEach(button => {
            button.addEventListener('click', () => {
                if (button.classList.contains('active')) {
                    button.classList.remove('active');
                    return;
                }

                moveButtons.forEach(btn => btn.classList.remove('active'));

                button.classList.add('active');

                const coords = button.getAttribute('data-coords');
                if (coords) {
                    const [lat, lng] = coords.split(',').map(value => parseFloat(value.trim()));

                    if (map && typeof map.setLocation === 'function') {
                        map.setLocation([lng, lat], mapZoom);
                    }
                }
            });
        });

    },
});

