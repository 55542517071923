import "./main.scss"
import "swiper/css"
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'
import 'swiper/css/controller'
import 'swiper/css/free-mode'

import "./js/index/version-check"
import "./js/index/mobile"
import "./js/index/logo"
import "./js/index/header"
import "./js/index/input"
import "./js/index/maskPhoneInput"
import "./js/index/feadback"
import "./js/index/modal"
import "./js/index/modal-drop-btn"
import "./js/index/last-section"
import "./js/index/quiz-section"

import "./js/parts/swiper"
import "./js/parts/show-more"
import "./js/parts/contact_form_7"
import "./js/parts/dropdown-menu"
import "./js/parts/observer"
import "./js/parts/section"
import "./js/parts/map"
import "./js/parts/details"

import "./js/ajax/gallery-ajax"
import "./js/ajax/news-ajax"
import "./js/ajax/people-ajax"
import "./js/ajax/reviews-ajax"
import "./js/ajax/reviews-second-ajax"

import "htmx.org"

import "./js/alpine";
import "./js/modal";