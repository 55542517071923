document.addEventListener('DOMContentLoaded', () => {
    const detailsSections = document.querySelectorAll('.section-details');

    detailsSections.forEach(section => {
        const list = section.querySelector('.details-btn-content');
        const toggleButton = section.querySelector('.details-btn');

        if (list && toggleButton) {
            const cards = list.querySelectorAll('.section-details__card');
            let totalHeight = 0;

            const calculateMaxHeight = () => {
                if (cards.length > 0) {
                    const parentTop = list.getBoundingClientRect().top;
                    const thirdCardBottom = cards[2] ? cards[2].getBoundingClientRect().bottom : parentTop;

                    totalHeight = thirdCardBottom - parentTop;
                    
                    if (!list.classList.contains('expanded')) {
                        list.style.maxHeight = `${totalHeight}px`;
                    }
                }
            };

            calculateMaxHeight();

            list.style.overflow = 'hidden';

            toggleButton.addEventListener('click', () => {
                const isActive = list.classList.toggle('expanded');
                toggleButton.classList.toggle('active', isActive);

                if (isActive) {
                    list.style.maxHeight = `${list.scrollHeight}px`; 
                } else {
                    list.style.maxHeight = `${totalHeight}px`;
                }
            });

            window.addEventListener('resize', calculateMaxHeight);
        }
    });
});
