document.addEventListener("DOMContentLoaded", function () {
    const sections = document.querySelectorAll("section");

    if (sections.length > 0) {
        const lastSection = sections[sections.length - 1];

        if (
            lastSection.classList.contains("section-quiz") ||
            lastSection.querySelector(".form-first") ||
            lastSection.querySelector(".contact-third")
        ) {
            lastSection.style.marginBottom = "0";
        }
    }
});