document.addEventListener('DOMContentLoaded', function () {
    const mainButton = document.querySelector('.drop-main-item .category-button');
    const dropdownMenu = document.querySelector('.section-services__dropdown');
    const categoryButtons = dropdownMenu?.querySelectorAll('.category-button');

    if (!mainButton || !dropdownMenu) {
        return;
    }

    dropdownMenu.classList.add('hidden');

    mainButton.addEventListener('click', () => {
        dropdownMenu.classList.toggle('open');

        if (categoryButtons) {
            categoryButtons.forEach(button => {
                const mainCategoryId = mainButton.getAttribute('data-category-id');
                const buttonCategoryId = button.getAttribute('data-category-id');

                if (!mainCategoryId || !buttonCategoryId) {
                    console.warn('Отсутствует атрибут data-category-id на кнопке.');
                    return;
                }

                if (buttonCategoryId === mainCategoryId) {
                    button.style.display = 'none';
                } else {
                    button.style.display = '';
                }
            });
        }
    });

    if (categoryButtons) {
        categoryButtons.forEach(button => {
            button.addEventListener('click', () => {
                if (button !== mainButton) {
                    mainButton.innerHTML = button.innerHTML;
                    mainButton.setAttribute('data-category-id', button.getAttribute('data-category-id'));
                }

                dropdownMenu.classList.remove('open');

                categoryButtons.forEach(btn => btn.classList.remove('active'));
                button.classList.add('active');
            });
        });
    } else {
        console.warn('Кнопки категорий не найдены.');
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const ccElements = document.querySelectorAll('.cc');

    if (!ccElements.length) {
        return;
    }

    ccElements.forEach(ccElement => {
        ccElement.addEventListener('click', () => {
            ccElement.classList.toggle('active');

            const btn = ccElement.querySelector('.drop-btn');
            if (btn) {
                if (btn.classList.contains('active')) {
                    btn.classList.remove('active');
                } else {
                    btn.classList.add('active');
                }
            }
        });
    });
});




