import Swiper from 'swiper';
import { Navigation, Thumbs, FreeMode, Pagination, Autoplay } from 'swiper/modules';


document.querySelectorAll(".banner").forEach((section) => {
  const bannerElement = section.querySelector(".banner-swiper");
  const pagination = section.querySelector(".banner-pagination");
  new Swiper(bannerElement, {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    cssMode: true,
    loop: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    pagination: {
      el: pagination,
      clickable: true,
    },
    mousewheel: true,
    keyboard: true,
  });
});


document.querySelectorAll(".works-swiper").forEach((swiperElement) => {
  const container = swiperElement.closest(".section-works");
  const nextButton = container.querySelector(".works-swiper-button-next");
  const prevButton = container.querySelector(".works-swiper-button-prev");

  new Swiper(swiperElement, {
    modules: [Navigation, FreeMode],
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    watchSlidesProgress: true,
    navigation: {
      nextEl: nextButton,
      prevEl: prevButton,
    },
  });
});


function initializeSwiper() {
  const swiper = new Swiper(".serviceSwiper", {
    modules: [FreeMode],
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesProgress: true,
  });

  const swiper2 = new Swiper(".serviceSwiper2", {
    modules: [Thumbs, Navigation],
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".service-swiper-button-next",
      prevEl: ".service-swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
}

document.addEventListener('ajaxContentLoaded', () => {
  initializeSwiper();
});


document.querySelectorAll(".section-people").forEach((section) => {
  const swiperElement = section.querySelector(".people-swiper");
  if (swiperElement) {
    const paginationElement = section.querySelector(".people-pagination");

    if (paginationElement) {
      new Swiper(swiperElement, {
        modules: [Pagination, FreeMode],
        slidesPerView: 1.2,
        spaceBetween: 30,
        freeMode: true,
        loop: false,
        pagination: {
          el: paginationElement,
          clickable: true,
        },
        mousewheel: true,
        keyboard: true,
      });
    }
  }
});


document.querySelectorAll(".section-reviews").forEach((section) => {
  const swiperElement = section.querySelector(".reviews-swiper");
  const nextButton = section.querySelector(".reviews-swiper-button-next");
  const prevButton = section.querySelector(".reviews-swiper-button-prev");

  new Swiper(swiperElement, {
    modules: [Navigation, FreeMode],
    slidesPerView: 1,
    spaceBetween: 10,
    freeMode: true,
    loop: false,
    watchSlidesProgress: true,
    navigation: {
      nextEl: nextButton,
      prevEl: prevButton,
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1300: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  });
});


document.querySelectorAll(".section-gallery").forEach((section) => {
  const swiperElement = section.querySelector(".gallery-swiper");
  const nextButton = section.querySelector(".gallery-swiper-button-next");
  const prevButton = section.querySelector(".gallery-swiper-button-prev");

  const swiper = new Swiper(".gallerySwiper", {
    modules: [FreeMode],
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesProgress: true,
  });

  const swiper2 = new Swiper(".gallerySwiper2", {
    modules: [Thumbs, Navigation],
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".gallery-swiper-button-next",
      prevEl: ".gallery-swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
});