document.addEventListener('DOMContentLoaded', () => {
    const observers = document.querySelectorAll('.observer');

    const updateLines = (container, line, dynamicLine) => {
        const nums = container.querySelectorAll('.section-work__num');

        if (nums.length < 2) {
            console.warn('Недостаточно элементов для расчета линии');
            return;
        }

        const firstNum = nums[0];
        let lastNum;

        if (window.innerWidth > 480) {
            lastNum = nums[nums.length - 1];
        } else {
            lastNum = nums[nums.length - 2] || nums[nums.length - 1];
        }

        if (!firstNum || !lastNum) {
            console.warn('Не удалось найти первый или последний элемент');
            return;
        }

        const containerRect = container.getBoundingClientRect();

        const calculatePosition = (elem) => {
            const rect = elem.getBoundingClientRect();
            return rect.top + rect.height / 2 - containerRect.top;
        };

        const lineTop = calculatePosition(firstNum);
        const lineHeight = calculatePosition(lastNum) - lineTop;

        requestAnimationFrame(() => {
            line.style.top = `${lineTop}px`;
            line.style.height = `${lineHeight}px`;
        });
    };

    const observerOptions = {
        root: null,
        rootMargin: '0px 0px -10% 0px',
        threshold: 0.5,
    };

    let lastActiveElement = null;

    const observerCallback = (entries) => {
        entries.forEach((entry) => {
            const { target, isIntersecting } = entry;

            if (isIntersecting) {
                target.classList.add('active');
                lastActiveElement = target;
            } else {
                target.classList.remove('active');

                if (lastActiveElement === target) {
                    lastActiveElement = null;
                }
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observers.forEach((section) => observer.observe(section));

    const containers = document.querySelectorAll('.section-works__list');

    const updateAllLines = () => {
        containers.forEach((container) => {
            const line = container.querySelector('.section-works__main-line');
            const dynamicLine = container.querySelector('.section-works__dynamic-line');
            if (container && line && dynamicLine) {
                updateLines(container, line, dynamicLine);
            }
        });
    };

    setTimeout(updateAllLines, 100);

    window.addEventListener('resize', updateAllLines);

    let isScrolling = false;
    window.addEventListener('scroll', () => {
        if (!isScrolling) {
            isScrolling = true;
            requestAnimationFrame(() => {
                updateAllLines();
                isScrolling = false;
            });
        }
    });

    window.addEventListener('load', updateAllLines);
});



