import Alpine from "alpinejs";

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import OpenModal from "./OpenModal"
Alpine.data("OpenModal", OpenModal)

Alpine.store("mainMenu", {
    open: false,
});

Alpine.start();
