import htmx from 'htmx.org';

export function initializeModalReviews() {
    const BtnModalId = document.querySelectorAll('.review-second-btn');

    BtnModalId.forEach(BtnModal => {
        BtnModal.addEventListener('click', async () => {
            const text = BtnModal.getAttribute('data-text');
            const title = BtnModal.getAttribute('data-title');

            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                mode: 'no-cors'
            };

            const form = new FormData();
            form.append('action', 'modal_reviews');
            form.append('title', title);
            form.append('text', text);
            const params = new URLSearchParams(form);
            options.body = params;

            try {
                let response = await fetch('/wp-admin/admin-ajax.php', options);
                let html = await response.text();
                if (html) {
                    const modalContent = document.querySelector('.modal-reviews');
                    modalContent.innerHTML = html;

                    const form = modalContent.querySelector('.form');
                    if (form) {
                        htmx.process(form);
                    }

                    const event = new CustomEvent('ajaxContentLoaded', {
                        detail: { modalContent },
                    });
                    document.dispatchEvent(event);
                }
            } catch (error) {
                console.error('Не удалось загрузить модалку отзывов:', error);
            }
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initializeModalReviews();
});
