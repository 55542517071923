document.addEventListener('DOMContentLoaded', () => {
    const assignReviewsData = () => {
        const peopleButtons = document.querySelectorAll('.people-show-more');
        peopleButtons.forEach((button, index) => {
            button.setAttribute('data-index', index + 1);
        });
    };

    assignReviewsData();
});

document.addEventListener('DOMContentLoaded', () => {
    const addShowMoreButtonListeners = () => {
        const showMoreButtons = document.querySelectorAll('.people-show-more');

        showMoreButtons.forEach((button) => {
            button.addEventListener('click', async (event) => {
                const button = event.target;
                const section = button.closest('.section-people'); 

                if (!section) {
                    console.error('Не удалось найти родительский блок секции.');
                    return;
                }

                const postId = button.getAttribute('data-post-id');
                const number = parseInt(button.getAttribute('data-number'));
                const offset = parseInt(button.getAttribute('data-offset'));
                const index = button.getAttribute('data-index');
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    mode: 'no-cors',  
                };

                const form = new FormData();
                form.append('action', 'load_more_people');
                form.append('post_id', postId);
                form.append('number', number);
                form.append('offset', offset);
                form.append('index', index);

                const params = new URLSearchParams(form);
                options.body = params;

                try {
                    let response = await fetch('/wp-admin/admin-ajax.php', options);
                    let html = await response.text();
                    if (html) {
                        const peopleList = section.querySelector('.people-content-block');
                        if (peopleList) {
                            peopleList.innerHTML = html;


                            const newShowMoreBtn = section.querySelector('.people-show-more');
                        }

                        addShowMoreButtonListeners();
                    }
                } catch (error) {
                    console.error('Ошибка при загрузке сотрудников:', error);
                }
            });
        });
    };

    addShowMoreButtonListeners();
});
