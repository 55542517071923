document.addEventListener('DOMContentLoaded', () => {
    function initServiceButtons() {
        const serviceBtn = document.querySelectorAll('.service-drop-btn');

        serviceBtn.forEach(btn => {
            btn.addEventListener('click', () => {
                const targeService = btn.getAttribute('data-num');

                document.querySelectorAll('.service-drop-element').forEach(element => {
                    element.classList.remove('active');
                });

                serviceBtn.forEach(btn => btn.classList.remove('active'));

                btn.classList.add('active');

                const targetElement = document.querySelector(`.service-drop-element[data-num="${targeService}"]`);
                if (targetElement) {
                    targetElement.classList.add('active');
                }
            });
        });
    }

    initServiceButtons();

    document.addEventListener('ajaxContentLoaded', (event) => {
        initServiceButtons(); 
    });
});
