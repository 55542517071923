document.addEventListener('htmx:configRequest', function(evt) {
    var actionInput = evt.target.querySelector('input[name="action"]');
    if (actionInput) {
        var indexValue = actionInput.getAttribute('data-index');
        if (indexValue) {
            evt.detail.parameters['index'] = indexValue;
            /* console.log(indexValue); */
        }
    }
});
