let counter = 1;

document.querySelectorAll('section').forEach((section) => {
    if (!section.classList.contains('content-title')) {
        section.id = `section-${counter}`;
        counter++;
    }
});

/* document.addEventListener('DOMContentLoaded', () => {
    const sections = document.querySelectorAll('section');
    if (sections.length > 0) {
        const lastSection = sections[sections.length - 1];

        if (lastSection.classList.contains('section-contact') ||
            lastSection.classList.contains('section-forms')) {
            lastSection.style.marginBottom = '0';
        } else {
            lastSection.style.marginBottom = '';
        }
    }
}); */
