document.addEventListener('DOMContentLoaded', function () {
    const items = document.querySelectorAll('.card-service');
    const showMoreBtn = document.querySelector('.show-more');
    const buttons = document.querySelectorAll('.category-button');
    const number = document.getElementById('services-list');
    let itemsPerPage = number.getAttribute('data-number');
    let currentPage = 1;
    let currentCategory = 'all';

    if (items.length === 0) {
        if (showMoreBtn) {
            showMoreBtn.style.display = 'none';
            return;
        }
    }
    
    const categoryBtn = document.querySelector('.category-button[data-category-id="all"]');
    if(categoryBtn){
        categoryBtn.classList.add('active');
    }

    function showItems() {
        let visibleItemsCount = 0;
        let categoryItemsCount = 0;

        items.forEach((item, index) => {
            const serviceCategory = item.getAttribute('data-category');

            if (currentCategory === 'all' || serviceCategory === currentCategory) {
                categoryItemsCount++;

                if (visibleItemsCount < currentPage * itemsPerPage) {
                    item.style.display = 'flex';
                    visibleItemsCount++;
                } else {
                    item.style.display = 'none';
                }
            } else {
                item.style.display = 'none';
            }
        });

        if (categoryItemsCount === 0 || visibleItemsCount >= categoryItemsCount) {
            showMoreBtn.style.display = 'none';
        } else {
            showMoreBtn.style.display = 'flex';
        }
    }

    function updateActiveClass(clickedButton) {
        buttons.forEach(button => {
            button.classList.remove('active');
        });
        clickedButton.classList.add('active');
    }

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            currentCategory = this.getAttribute('data-category-id');
            currentPage = 1;
            updateActiveClass(this);
            showItems();
        });
    });

    if(showMoreBtn){
        showMoreBtn.addEventListener('click', function () {
            currentPage++;
            showItems();
        });
        showItems();
    }


});