document.addEventListener('DOMContentLoaded', function () {
    const dropdownButton = document.querySelector('.quiz-btn-dropdown .quiz-drop-item.active');
    const dropdownMenu = document.querySelector('.quiz-dropdown');
    const dropdownItems = document.querySelectorAll('.quiz-dropdown .quiz-drop-item');
    const hiddenInput = document.querySelector('input[name="action"]');

    if (dropdownButton) {
        dropdownButton.addEventListener('click', () => {
            dropdownMenu.classList.toggle('active');
            const activeDataDrop = dropdownButton.dataset.drop;
            dropdownItems.forEach(item => {
                item.classList.toggle('hidden', item.dataset.drop === activeDataDrop);
            });
        });
    }


    dropdownItems.forEach(item => {
        item.addEventListener('click', () => {
            dropdownItems.forEach(i => i.classList.remove('hidden'));

            dropdownMenu.insertBefore(item, dropdownMenu.firstChild);
            dropdownName = dropdownMenu.dataset.dropdown;
            dropdownButton.textContent = item.textContent;
            dropdownButton.dataset.drop = item.dataset.drop;

            const inputName = `dropdown-${dropdownName}`;
            let inputElement = document.querySelector(`input[name="${inputName}"]`);
            if (!inputElement) {
                inputElement = document.createElement('input');
                inputElement.type = 'hidden';
                inputElement.name = inputName;
                dropdownMenu.parentElement.appendChild(inputElement);
            }
            inputElement.value = item.dataset.value;

            dropdownMenu.classList.remove('active');

            console.log(inputElement.name, inputElement.value);
        });
    });

    if (dropdownButton) {
        document.addEventListener('click', e => {
            if (!dropdownButton.contains(e.target) && !dropdownMenu.contains(e.target)) {
                dropdownMenu.classList.remove('active');
            }
        });
    }
});


document.addEventListener("DOMContentLoaded", function () {
    let currentPage = 1;

    const quizPages = document.querySelectorAll(".quiz-page");
    const quizCounts = document.querySelectorAll(".quiz-count");
    const nextButton = document.querySelectorAll(".quiz-btn-next");
    const prevButton = document.querySelectorAll(".quiz-btn-prev");
    const lastButton = document.querySelector(".quiz-btn");

    function updateActiveState(pageNumber) {
        quizPages.forEach(page => page.classList.remove("active"));
        quizCounts.forEach(count => count.classList.remove("active"));

        const activePage = document.querySelector(`.quiz-page[data-page="${pageNumber}"]`);
        const activeCount = document.querySelector(`.quiz-count[data-page="${pageNumber}"]`);

        if (activePage) activePage.classList.add("active");
        if (activeCount) activeCount.classList.add("active");

        prevButton.forEach(button => button.style.display = pageNumber === 1 ? "none" : "flex");
        nextButton.forEach(button => button.style.display = pageNumber === quizPages.length ? "none" : "flex");
        if (lastButton) lastButton.style.display = pageNumber === quizPages.length ? "flex" : "none";
    }

    nextButton.forEach(button => {
        button.addEventListener("click", function () {
            if (currentPage < quizPages.length) {
                currentPage++;
                updateActiveState(currentPage);
            }
        });
    });

    prevButton.forEach(button => {
        button.addEventListener("click", function () {
            if (currentPage > 1) {
                currentPage--;
                updateActiveState(currentPage);
            }
        });
    });

    quizCounts.forEach(count => {
        count.addEventListener("click", function () {
            const selectedCount = parseInt(count.getAttribute("data-page"));

            currentPage = selectedCount;
            updateActiveState(currentPage);
        });
    });

    updateActiveState(currentPage);
});


document.addEventListener("DOMContentLoaded", function () {
    const radioGroups = document.querySelectorAll('.quiz-second__page--one-list');

    radioGroups.forEach(group => {
        const radios = group.querySelectorAll('input[type="radio"]');

        radios.forEach(radio => {
            radio.addEventListener('change', function () {
                group.querySelectorAll('.quiz-radio').forEach(label => {
                    label.classList.remove('active');
                });

                if (radio.checked) {
                    radio.closest('.quiz-radio').classList.add('active');
                }
            });
        });
    });
});